@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html {
  @apply text-base;
}

@layer base {
  html {
    font-family: --font-redhat, system-ui, sans-serif;
  }
}

.ck-editor__editable {
  min-height: 400px;
}

.cropper-modal {
  background-color: #fff !important;
  opacity: 0.5;
}

.cropper-square .cropper-view-box,
.cropper-square .cropper-face {
  border-radius: 0; /* Square corners for react cropper*/
}

.cropper-view-box,
.cropper-face {
  border-radius: 50%; /* Rounded corners by default */
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #eb773d4d;
  outline-color: #eb773d !important;
  outline: 0;
}

.cropper-line {
  background-color: #eb773d !important;
}

.cropper-point {
  background-color: #eb773d !important;
}

@layer base {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #c0c0c0;
  }

  .scrollable {
    overflow-y: scroll;
  }
}

/* to remove default margin added by toaster */
.go3958317564 {
  margin: 0 !important;
}
/* Common styles for both organization-info-content and news-details-content */
.organization-info-content,
.news-details-content,
.event-details-content {
  @apply max-w-[756px] w-full;
}

.organization-info-content h2,
.news-details-content h2,
.event-details-content h2 {
  @apply text-[22px] lg:text-2xl font-bold my-5;
}

.organization-info-content h3,
.news-details-content h3,
.event-details-content h3 {
  @apply text-lg lg:text-xl font-bold my-5;
}

.organization-info-content figure,
.news-details-content figure,
.event-details-content figure {
  @apply my-5;
}

.organization-info-content iframe,
.news-details-content iframe,
.event-details-content iframe {
  @apply w-full;
}

.organization-info-content p,
.news-details-content p,
.event-details-content p {
  @apply my-5;
}

.organization-info-content a,
.news-details-content a,
.event-details-content a {
  @apply underline text-blue-500 visited:text-blue-700;
}

.organization-info-content .wp-element-button,
.news-details-content .wp-element-button,
.event-details-content .wp-element-button {
  @apply my-2 py-2 px-8 rounded-full h-[40px] whitespace-nowrap hover:shadow-lg text-sm bg-primary text-black no-underline w-auto cursor-pointer inline-block;
}

.organization-info-content .wp-block-quote,
.news-details-content .wp-block-quote,
.event-details-content .wp-block-quote {
  @apply italic;
}

/* Specific override for news-details-content and event-details-content figure */
.news-details-content figure,
.event-details-content figure {
  @apply max-w-[595px];
}

.rdw-editor-toolbar {
  border: none !important;
  border-radius: 20px !important;
}

.article--content_text ul li,
.article--content_text ol li {
  position: relative;
  padding-left: 20px;
}
.article--content_text ul li:before,
.article--content_text ol li:before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #f8c042;
  position: absolute;
  top: 9px;
  left: 0;
}

.DraftEditor-editorContainer {
  padding: 0 10px;
}

/* Hide the default content of slick arrows */
.slick-arrow::before,
.slick-arrow::after {
  content: none !important;
}

.edit-organization-info-form .cropper-container {
  @apply border border-brand-light-gray;
}

.job-details-content ul {
  @apply list-none list-inside;
}

.job-details-content p,
.job-details-content span {
  font-size: 0.875rem;
  line-height: 1.7rem;
  white-space: pre-line;
}

.wf-registration {
  @apply bg-gray-50
}

@layer base {
  h1 {
    @apply text-4xl font-bold text-brand-body-primary leading-tight; /* Tailwind classes for h1 */
  }
  h2 {
    @apply text-3xl font-semibold text-brand-body-primary leading-snug; /* Tailwind classes for h2 */
  }
  h3 {
    @apply text-2xl font-semibold text-brand-body-primary leading-snug; /* Tailwind classes for h3 */
  }
  h4 {
    @apply text-xl font-semibold text-brand-body-primary leading-snug; /* Tailwind classes for h4 */
  }
  h5 {
    @apply text-lg font-medium text-brand-body-primary leading-relaxed; /* Tailwind classes for h5 */
  }
  h6 {
    @apply text-base font-medium text-brand-body-primary leading-relaxed; /* Tailwind classes for h6 */
  }
  p {
    @apply text-base text-brand-body-primary leading-relaxed; /* Tailwind classes for paragraphs */
  }
  ul {
    @apply list-disc list-inside; /* Tailwind classes for unordered lists */
  }
  ol {
    @apply list-decimal list-inside; /* Tailwind classes for ordered lists */
  }
  li {
    @apply mb-2; /* Tailwind classes for list items */
  }
  blockquote {
    @apply border-l-4 border-gray-300 pl-4 italic text-brand-body-secondary; /* Tailwind classes for blockquotes */
  }
}

input[data-focused="true"]::-webkit-contacts-auto-fill-button {
  display: none !important;
  visibility: hidden !important;
}

input[data-focused="true"]::-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  background-color: transparent !important;
}

/* Add a flip card perspective to create a 3D effect */
.flip-card-front, .flip-card-back {
  transform-style: preserve-3d;
  transition: transform 0.8s ease;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(-180deg);
}

.flip-card:hover .flip-card-front {
  transform: rotateY(180deg);
}

.flip-card:hover .flip-card-back {
  transform: rotateY(0);
}

.overflow-wrap-anywhere {
    overflow-wrap: anywhere;
}
